<template>
    <div>
        <mercur-card class="mt-n4 mb-4">
            <div class="text-center" v-if="isLoading">
                <mercur-spinner></mercur-spinner>
            </div>
            <div v-if="!isLoading">
                <h3 class="font-weight-normal">{{ isEditing ? 'Edit Field' : 'Add New Field' }}</h3>
                <form @submit.prevent="isEditing ? update() : create()">
                    <mercur-input :disabled="isSystemField" v-model="form.fieldName" :class="getValidationClass($v, 'fieldName')">
                        Field Name
                        <template #note>
                            <span class="form-error" v-if="!$v.form.fieldName.required && !isSystemField">Field name is required</span>
                        </template>
                    </mercur-input>
                    <mercur-input v-model="form.defaultValue" :class="getValidationClass($v, 'fieldName')">
                        Default value
                    </mercur-input>
                    <div>
                        <mercur-button type="submit" v-if="hasPermission('MerchantCentral/updateField')"
                                   :disabled="isDisabled || isSystemField" class="btn btn-primary">
                            {{ isEditing ? 'Save' : 'Add' }}
                        </mercur-button>
                    </div>
                </form>
            </div>
        </mercur-card>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

export default {
    props: ['isEditing'],
    name: 'FieldContent',
    mixins: [FormMixin],
    data: () => ({
        requiredSystemFields: ['productHash', 'sku'],
        form: {},
        isLoading: false,
        isDisabled: false,
    }),

    validations: {
        form: {
            fieldName: {
                required,
                isFieldNameValid (fieldName) {
                    const regex = /^(?!(xml|[-\\d\\W]))([A-Za-z0-9\\-\\_\\.]+)+$/
                    return regex.test(fieldName)
                },
            },
        },
    },

    methods: {
        ...mapActions('productfeed', ['getCurrentField']),

        async update () {
            this.$v.$touch()
            if (this.$v.$invalid || this.$v.$error) {
                return
            }
            this.isDisabled = true

            const url = CONFIG.API.ROUTES.PRODUCT_FEEDS.FIELDS.UPDATE
            const data = {
                fieldId: this.$route.params.fieldId,
                fieldName: this.form.fieldName,
                defaultValue: this.form.defaultValue,
            }

            this.post(url, data, 'Field was updated').then((res) => {
                if (res) {
                    this.$router.replace({
                        name: 'FieldsWrapper',
                    })
                }
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: data.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.isDisabled = false
            })
        },

        async create () {
            this.$v.$touch()
            if (this.$v.$invalid || this.$v.$error) {
                return
            }
            this.isDisabled = true

            const url = CONFIG.API.ROUTES.PRODUCT_FEEDS.FIELDS.CREATE
            const data = {
                fieldName: this.form.fieldName,
                fieldType: 'CUSTOM',
                defaultValue: this.form.defaultValue,
            }

            this.post(url, data, 'Field was created').then((res) => {
                if (res) {
                    this.$router.replace({
                        name: 'FieldsWrapper',
                    })
                }
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: data.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.isDisabled = false
            })
        },
    },

    computed: {
        ...mapState('productfeed', ['currentField']),

        isSystemField () {
            return this.currentField.fieldType === 'SYSTEM'
        },
    },

    async mounted () {
        /// If editing get the current product feed by id
        if (this.isEditing) {
            this.isLoading = true
            await this.getCurrentField(this.$route.params.fieldId)
            this.form.fieldName = this.currentField.fieldName
            this.form.defaultValue = this.currentField.defaultValue
            this.isLoading = false
        }
    },
}
</script>
